<script>
import axios from 'axios'
import { BeaconapiUrl } from '../constants/config'
import { mapMutations } from 'vuex'
import { instance } from '../../src/request/app-axios.js'
export default {
  data () {
    return {
      followUp: {},
      statusCode: null,
      errorMessage: null
    }
  },
  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'setModalDangerMessage',
      'setModalAlertMessage',
      'setModalInfoMessage',
      'appendInfoMessage',
      'appendDangerMessage'
    ]),
    async getFollowUpForViewModalByID (followUpID) {
      await axios
        .get(`${BeaconapiUrl}/api/follow-up/${followUpID}`)
        .then((result) => {
          this.followUp = result.data
        })
        .catch((error) => {
          this.setModalDangerMessage(error)
        })
    },
    async addFollowUp (followUp) {
      followUp.createdByID = localStorage.getItem('employeeID')
      followUp.lastModifiedByID = localStorage.getItem('employeeID')

      await instance
        .post(`${BeaconapiUrl}/api/follow-up`, followUp)
        .then((result) => {
          this.statusCode = result.status
          if (this.statusCode === 200) {
            this.setInfoMessage('Follow Up added successfully!')
          }
        })
        .catch((error) => {
          this.setModalDangerMessage(error)
        })
    },
    async getFollowUpForEditModalByID (followUpID) {
      await axios
        .get(`${BeaconapiUrl}/api/follow-up/edit/${followUpID}`)
        .then((result) => {
          this.followUp = result.data
        })
        .catch((error) => {
          this.setModalDangerMessage(error)
        })
    },
    async updateFollowUp (followUp) {
      followUp.lastModifiedByID = localStorage.getItem('employeeID')

      await axios
        .put(`${BeaconapiUrl}/api/follow-up/update`, followUp)
        .then((result) => {
          this.statusCode = result.status
          if (this.statusCode === 200) {
            this.setInfoMessage('Follow Up updated successfully!')
          }
        })
        .catch((error) => {
          this.setModalDangerMessage(error)
        })
    },
    async completeFollowUp (followUp) {
      followUp.completedBy = localStorage.getItem('employeeID')
      await axios
        .put(`${BeaconapiUrl}/api/follow-up/complete`, followUp)
        .then((result) => {
          this.statusCode = result.status
          if (this.statusCode === 200) {
            this.setInfoMessage('Follow Up Completed!')
          }
        })
        .catch((error) => {
          this.setModalDangerMessage(error)
        })
    }
  }
}
</script>
