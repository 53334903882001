<script>
import axios from 'axios'
import { BeaconapiUrl } from '../constants/config'
import { mapMutations } from 'vuex'
import { instance } from '../../src/request/app-axios.js'

export default {
  data () {
    return {
      activties: [],
      categories: [],
      activityTypes: [],
      activity: {},
      companyContacts: []
    }
  },

  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'appendInfoMessage',
      'appendDangerMessage'
    ]),
    async addNewActivity (activity) {
      return await instance
        .post(`${BeaconapiUrl}/api/activity`, activity)
        .then((result) => {
          this.statusCode = result.status
          if (this.statusCode === 200) {
            this.setInfoMessage('Activity added successfully!')
            return result.data
          }
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async getActivityTypeCategories () {
      return await axios
        .get(`${BeaconapiUrl}/api/activity/categories`)
        .then((result) => { this.categories = result.data })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async getCompanyContacts (companyId) {
      await axios
        .get(`${BeaconapiUrl}/api/company-contact?companyID=${companyId}`)
        .then((result) => {
          this.companyContacts = result.data
        })
        .catch((error) => {
          this.setModalDangerMessage(error)
        })
    },
    async getActivityTypesByCategoryID (activityTypeCategoryID) {
      return await axios
        .get(`${BeaconapiUrl}/api/activity/by-category`, { params: { activityTypeCategoryID: activityTypeCategoryID } })
        .then((result) => { this.activityTypes = result.data })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async deleteActivity (activity) {
      await axios
        .delete(`${BeaconapiUrl}/api/activity/`, { data: activity })
        .then((result) => {
          this.statusCode = result.status
          if (this.statusCode === 200) {
            this.setInfoMessage(result.data)
          }
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    }
  }
}
</script>
