<template>
  <b-modal
    id="modalbasic"
    v-b-modal.modal-center
    ref="modalbasic"
    @hide="close()"
    v-model="isVisible"
    :title="this.title"
    @cancel="close()"
    @close="close()"
    :no-close-on-backdrop="true"
    size="lg"
  >
    <Messages />
    <b-row>
      <b-col>
        <b-card sm="12">
          <Confirmation ref="Confirmation"></Confirmation>
          <b-form>
            <b-row>
              <b-colxx sm="6">
                <b-form-group label="Activity Category" class="lg">
                  <b-form-select
                    v-model="$v.activity.activityTypeCategoryID.$model"
                    :state="!$v.activity.activityTypeCategoryID.$error"
                    :options="categories"
                    value-field="activityTypeCategoryID"
                    text-field="description"
                    plain
                    @change="updateActivityTypes()"
                  ></b-form-select>
                  <div
                    class="text-danger"
                    :state="!$v.activity.activityTypeCategoryID.$error"
                    v-if="
                      !$v.activity.activityTypeCategoryID.required ||
                        !$v.activity.activityTypeCategoryID.$dirty
                    "
                  >
                    This field is required.
                  </div>
                </b-form-group>
              </b-colxx>

              <b-colxx sm="6">
                <b-form-group label="Activity Type" class="lg">
                  <b-form-select
                    v-model="$v.activity.activityTypeID.$model"
                    :options="activityTypes"
                    value-field="activityTypeID"
                    text-field="description"
                    :disabled="
                      $v.activity.activityTypeCategoryID.$model == null ||
                        $v.activity.activityTypeCategoryID.$model == 0
                    "
                    plain
                  ></b-form-select>
                </b-form-group>
              </b-colxx>
            </b-row>

            <b-row v-if="this.activity.activityTypeID == 3">
              <b-colxx sm="6">
                <b-form-group label="Other" class="lg">
                  <b-form-input
                    v-model.trim="$v.activity.activityTypeOtherText.$model"
                    :state="!$v.activity.activityTypeOtherText.$error"
                  >
                  </b-form-input>
                  <div
                    class="text-danger"
                    :state="!$v.activity.activityTypeOtherText.$model.$error"
                    v-if="
                      !$v.activity.activityTypeOtherText.required ||
                        !$v.activity.activityTypeOtherText.$dirty
                    "
                  >
                    This field is required.
                  </div>
                </b-form-group>
              </b-colxx>
            </b-row>

            <b-row>
              <b-colxx sm="6">
                <b-form-group label="Subject" class="md">
                  <b-form-input
                    v-model.trim="$v.activity.subject.$model"
                    :state="!$v.activity.subject.$error"
                  >
                  </b-form-input>
                  <div class="text-danger"
                    v-if="!$v.activity.subject.maxLengthValue &&
                        !$v.activity.subject.$model.$dirty &&
                        $v.activity.subject.$model.length > 0">
                    Must be fewer than 255 characters
                  </div>
                  <div
                    class="text-danger"
                    v-if="
                      !$v.activity.subject.$model.required &&
                        !$v.activity.subject.$model.$dirty &&
                        $v.activity.subject.$model.length <= 0
                    "
                  >
                    This field is required.
                  </div>
                </b-form-group>
              </b-colxx>

              <b-colxx sm="6">
                <b-form-group label="Comments" class="lg">
                  <b-form-textarea
                    v-model.trim="$v.activity.comments.$model"
                    :state="!$v.activity.comments.$error"
                    >>
                  </b-form-textarea>
                  <div
                    class="text-danger"
                    v-if="!$v.activity.comments.required || !$v.activity.comments.$dirty">
                    This field is required.
                  </div>
                </b-form-group>
              </b-colxx>
            </b-row>

            <b-row>
              <b-col lg="8">
                <b-form-group
                  label="Company Contacts"
                  label-for="companyContacts"
                  label-cols-sm="4"
                  label-align-sm="left"
                >
                  <multiselect
                    id="companyContacts"
                    v-model="myContact"
                    :options="companyContacts"
                    :close-on-select="true"
                    placeholder="Select Contacts..."
                    label="displayData"
                    track-by="contactID"
                  >
                  </multiselect>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-button pill variant="primary" @click="addSelectedCompanyContact()"
                  >Add Company Contact</b-button
                >
              </b-col>
            </b-row>
            <b-card title="Activity Contacts">
              <div v-for="(contact, index) in activity.companyContacts"
                :key="index"
                >
                <b-row>
                  <b-col lg="3">
                    <b-form-group>
                      <b-form-input
                        placeholder="First name"
                        v-model="contact.firstName"
                        :state="$v.activity.companyContacts.$each[index].firstName.required"
                        required
                      ></b-form-input>
                      <div v-if="!$v.activity.companyContacts.$each[index].firstName.required"
                        class="text-danger"
                      >
                        This field is required.
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col lg="3">
                    <b-form-group>
                      <b-form-input
                        placeholder="Last name"
                        v-model="activity.companyContacts[index].lastName"
                        :state="$v.activity.companyContacts.$each[index].lastName.required"
                        required
                      ></b-form-input>
                      <div v-if="!$v.activity.companyContacts.$each[index].lastName.required"
                        class="text-danger"
                      >
                        This field is required.
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col lg="3">
                    <b-form-group>
                      <b-form-input
                        placeholder="Contact Info"
                        v-model="activity.companyContacts[index].contactData"
                        :state="$v.activity.companyContacts.$each[index].contactData.required"
                        required
                      ></b-form-input>
                      <div v-if="!$v.activity.companyContacts.$each[index].contactData.required"
                        class="text-danger"
                      >
                        This field is required.
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col lg="3">
                    <b-form-group>
                      <b-button variant="danger" @click="deleteEvent(index)"
                        >Delete</b-button
                      >
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <b-row>
              <b-col>
                <b-button
                  pill
                  variant="primary"
                  class="top-right-button mr-2 float-left"
                  @click="addExternalContact()"
                  >Add External Contact</b-button
                >
              </b-col>
              </b-row>
            </b-card>
            <br />
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <template #modal-footer="{ cancel }">
      <b-button variant="outline-danger" class="mr-1" @click="cancel()">
        Cancel
      </b-button>
      <b-button
        :disabled="$v.$invalid || isSaving"
        variant="success"
        class="mr-1"
        @click="saveActivity()"
      >
        Save Activity
      </b-button>
    </template>
  </b-modal>
</template>
<style lang="scss" scoped>
.is-invalid {
  border: 1px solid #dc3545 !important;
}
.multiselect-is-invalid ::v-deep .multiselect__tags {
  border: 1px solid #dc3545 !important;
}
.alignWithLabel {
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
}
</style>

<script>
import { validationMixin } from 'vuelidate'
import ActivityMixin from '../../mixins/ActivityMixin.vue'
import FollowUpMixin from '../../mixins/FollowUpMixin.vue'
import CompanyContactMixin from '../../mixins/CompanyContactMixin.vue'
import Multiselect from 'vue-multiselect'
import { mapMutations } from 'vuex'
import ModalMessages from '../../containers/navs/ModalMessages.vue'
import Confirmation from '../Common/Confirmation.vue'

const {
  required,
  maxLength,
  requiredIf
} = require('vuelidate/lib/validators')

export default {
  components: {
    multiselect: Multiselect,
    Messages: ModalMessages,
    Confirmation: Confirmation
  },

  data () {
    return {
      title: undefined,
      isVisible: false,
      isShow: false,
      myContact: ''
      // contacts: [
      //   {
      //     id: 1,
      //     firstName: '',
      //     lastName: '',
      //     contactData: ''
      //   }
      // ]
    }
  },
  async created () {
    this.activity = {
      activityTypeID: null,
      subject: '',
      activityTypeOtherText: '',
      comments: '',
      companyID: 0,
      activityTypeCategoryID: 0,
      companyContacts: []
    }
    this.isSaving = false
  },
  mixins: [validationMixin, ActivityMixin, CompanyContactMixin, FollowUpMixin],
  validations: {
    activity: {
      activityTypeID: {},
      subject: {
        maxLengthValue: maxLength(255),
        required
      },
      activityTypeCategoryID: {
        required
      },
      activityTypeOtherText: {
        maxLengthValue: maxLength(50),
        required: requiredIf(function () {
          return this.activity.activityTypeID === 3
        })
      },
      comments: {
        required
      },
      companyContacts: {
        required, // this validates that the user has selected at least one contact.
        $each: { // These validations make sure they specify a name and contact info each time they click Add New Contact
          firstName: {
            required
          },
          lastName: {
            required
          },
          contactData: {
            required
          }
        }
      }
    }
  },
  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setModalDangerMessage',
      'setModalInfoMessage'
    ]),

    async show (opts = {}) {
      this.title = opts.title

      this.initialize()

      if (opts.companyID) {
        this.activity.companyID = opts.companyID
      }

      if (opts.followUpID) {
        this.followUpID = opts.followUpID
      }

      this.loadCompanyContacts(opts.companyID)
      this.getActivityTypeCategories()

      if (opts.exitFunction) {
        this.exitFunction = opts.exitFunction
      }

      this.isVisible = true
    },
    addSelectedCompanyContact () {
      if (this.myContact) {
        this.activity.companyContacts.push(this.myContact)
        this.myContact = null
      }
    },
    addExternalContact () {
      this.activity.companyContacts.push({
        firstName: '',
        lastName: '',
        contactData: ''
      })
    },
    deleteEvent (index) {
      this.activity.companyContacts.splice(index, 1)
    },
    close () {
      if (this.exitFunction) {
        this.exitFunction()
      }

      this.activityID = 0
      this.setModalDangerMessage('')
      this.setModalInfoMessage('')
      this.isVisible = false
    },
    async loadCompanyContacts (companyID) {
      await this.getActivityContactDataByCompanyId(companyID)
      this.companyContacts = this.mapCompanyContacts()
    },
    mapCompanyContacts () {
      return this.companyContactData.map(r => {
        return {
          displayData: `${r.firstName} ${r.lastName} - ${r.contactData}`,
          contactID: r.contactID,
          firstName: r.firstName,
          lastName: r.lastName,
          contactData: r.contactData
        }
      })
    },
    onValidateActivityFormSubmit () {
      this.$v.activity.$touch()
      if (!this.$v.activity.$error) {
        this.saveActivity()
      } else {
        this.setDangerMessage('All fields are required.')
      }
    },
    async saveActivity () {
      if (this.isSaving !== true) {
        this.isSaving = true
        var activityID = await this.addNewActivity(this.activity)
        if (this.statusCode === 200) {
          if (this.followUpID) {
            this.completeFollowUp({
              followUpID: this.followUpID,
              activityID: activityID
            })
          }
          this.isSaving = false
          this.close()
        }
      }
    },
    cancel () {
      this.close()
    },
    async updateActivityTypes () {
      await this.getActivityTypesByCategoryID(
        this.activity.activityTypeCategoryID
      )
    },
    initialize () {
      this.activity = {
        activityTypeID: null,
        subject: '',
        activityTypeOtherText: '',
        comments: '',
        companyID: 0,
        activityTypeCategoryID: 0,
        companyContacts: []
      }
      this.followUpID = null
      this.isSaving = false
    },
    multiSelectLabelFormatter ({ lastName, firstName, contactData }) {
      if (contactData !== '' && contactData !== null) {
        return `${lastName}, ${firstName} - ${contactData}`
      } else {
        return `${lastName}, ${firstName}`
      }
    }
  },
  computed: {},
  mounted () {}
}
</script>

<style scoped>
.form-control.is-invalid {
  border-color: #dc3545;
}
</style>

<style lang="scss" scoped>
.is-invalid {
  border: 1px solid #dc3545 !important;
}

.multiselect-is-invalid ::v-deep .multiselect__tags {
  border: 1px solid #dc3545 !important;
}

select[disabled] {
  background-color: #dddddd;
  opacity: 0.3;
}
</style>
