<template>
  <div>
    <confirmation ref="Confirmation"></confirmation>
    <activity-modal ref="ActivityModal"></activity-modal>
    <datatable-heading
      :hasTitleSection="false"
      :changePageSize="changePageSize"
      :searchChange="searchChange"
      :from="from"
      :to="to"
      :total="total"
      :perPage="perPage"
      :titleAndButton="true"
    >
    <template v-slot:button>
      <div class="title-and-right-element">
        <page-title :title="'Activity'" :identifier="'Company #'+ companyID"></page-title>
        <b-button
          v-b-modal.modalright
          variant="primary"
          size="lg"
          class="top-right-button mr-2"
          @click="addActivity()"
        >Add New</b-button>
      </div>
    </template>
    </datatable-heading>

    <b-row>
      <b-colxx xxs="12">
        <b-card>
          <vuetable
            ref="vuetable"
            track-by="activityID"
            :api-url="apiBase"
            :query-params="makeQueryParams"
            :per-page="perPage"
            :reactive-api-url="false"
            :fields="fields"
            :css="css.table"
            pagination-path
            :sort-order="sortOrder"
            @vuetable:pagination-data="onPaginationData"
            @vuetable:load-error="handleLoadError"
            @vuetable:cell-rightclicked="rightClicked"
          >
            <template slot="comments" slot-scope="props">
              <read-more v-if="props.rowData.comments" more-str="read more" :text="props.rowData.comments" less-str="read less" :max-chars="100" />
            </template>
            <template slot="activityContactInfo" slot-scope="props">
              <read-more-list separator="," :listText="props.rowData.activityContactInfo" />
            </template>
          </vuetable>
        </b-card>
        <vuetable-pagination-bootstrap
          class="mt-4"
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        />
      </b-colxx>
    </b-row>

    <v-contextmenu ref="contextmenu" v-if="canDeleteActivity">
      <v-contextmenu-item @click="onContextMenuAction('delete')">
        <i class="simple-icon-trash" />
        <span>Delete</span>
      </v-contextmenu-item>
    </v-contextmenu>
  </div>
</template>
<script>
import ActivityModal from '../../../../components/Modals/ActivityModal.vue'
import { BeaconapiUrl } from '../../../../constants/config'
import Confirmation from '../../../../components/Common/Confirmation.vue'
import CssTableConfig from '../../../../constants/VuetableBootstrapconfig'
import DatatableHeading from '../../../../containers/datatable/DatatableHeading'
import ParentEntityPageTitle from '../../../../components/Common/ParentEntityPageTitle.vue'
import ReadMore from '../../../../../node_modules/vue-read-more/components/ReadMoreComponent.vue'
import ReadMoreList from '../../../../components/Listing/ReadMoreList.vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '../../../../components/Common/VuetablePaginationBootstrap'

import ActivityMixin from '../../../../mixins/ActivityMixin.vue'

import moment from 'moment/moment.js'

// so we can set our messages in the layout if needed
import { mapMutations } from 'vuex'

export default {
  props: {
    companyID: {
      type: Number,
      default: 0
    }
  },
  components: {
    Vuetable,
    'vuetable-pagination-bootstrap': VuetablePaginationBootstrap,
    'datatable-heading': DatatableHeading,
    'read-more': ReadMore,
    Confirmation,
    'read-more-list': ReadMoreList,
    'activity-modal': ActivityModal,
    'page-title': ParentEntityPageTitle
  },
  mixins: [ActivityMixin],
  data () {
    // because the id field is used in other places throughout this page here it should be
    // identified as a variable so that we don't have it hardcoded everywhere
    return {
      isLoad: false,
      apiBase: BeaconapiUrl + '/api/company/' + this.companyID + '/activity',
      sort: '',
      page: 1,
      perPage: 50,
      search: '',
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      items: [],
      selectedItems: [],
      idfield: 'activityID',
      css: CssTableConfig,
      sortOrder: [{
        field: 'createdDate',
        direction: 'desc'
      }],
      fields: [
        {
          name: 'activityID',
          sortField: 'activityID',
          title: 'ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '5%'
        },
        {
          name: 'category',
          sortField: 'category',
          title: 'Category',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%'
        },
        {
          name: 'type',
          sortField: 'type',
          title: 'Type',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%'
        },
        {
          name: 'subject',
          sortField: 'subject',
          title: 'Subject',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%'
        },
        {
          name: '__slot:comments',
          title: 'Comments',
          titleClass: '',
          dataClass: 'text-muted',
          width: '20%'
        },
        {
          name: '__slot:activityContactInfo',
          sortField: 'activityContactInfo',
          title: 'Contact Info',
          titleClass: '',
          dataClass: 'text-muted',
          width: '20%'
        },
        {
          name: 'createdBy',
          sortField: 'createdBy',
          title: 'Created By',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%'
        },
        {
          name: 'createdDate',
          sortField: 'createdDate',
          title: 'Created Date',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%',
          callback: this.formatDate
        }
      ]
    }
  },
  methods: {
    ...mapMutations(['setDangerMessage', 'setAlertMessage', 'setInfoMessage', 'appendInfoMessage']),
    // Vue Table plumbing methods (standardized)

    makeQueryParams (sortOrder, currentPage, perPage) {
      this.selectedItems = []
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].field + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search
        }
        : {
          page: currentPage,
          per_page: this.perPage,
          search: this.search
        }
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    changePageSize (perPage) {
      this.perPage = perPage
      this.refreshVueTable()
    },
    searchChange (val) {
      this.search = val
      this.refreshVueTable()
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    getIndex (value, arr, prop) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i][prop] === value) {
          return i
        }
      }
      return -1
    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },
    rightClicked (dataItem, field, event) {
      if (this.canDeleteActivity) {
        event.preventDefault()
        this.rightClickedItem = dataItem

        if (!this.$refs.vuetable.selectedTo.includes(dataItem)) {
          this.$refs.vuetable.selectedTo = [dataItem]
        }
        this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
        this.selectedItems = this.$refs.vuetable.selectedTo
      }
    },
    // // End of Standard Vue Table methods

    // // Component-specific Vue Table methods

    onContextMenuAction (action) {
      switch (action) {
        case 'delete': {
          this.$refs.Confirmation.show({
            title: 'Delete Activity',
            message: 'Are you sure you want to delete this activity?',
            okButton: 'Yes'
          }).then(async (result) => {
            if (result) {
              await this.deleteActivity(this.selectedItems[0]).then(() => {
                this.refreshVueTable()
              })
            } else {
              this.refreshVueTable()
            }
          })

          break
        }
        default:
          break
      }
    },

    // // End of Component-specific Vue table methods

    // // Component-specific methods
    formatDate (value) {
      if (value === null) {
        return ''
      } else {
        return moment(value, 'YYYY-MM-DD').format('MMM Do, YYYY')
      }
    },

    // End of Component-specific methods
    async addActivity () {
      await this.$refs.ActivityModal.show({
        title: 'Add New Activity',
        companyID: this.companyID,
        exitFunction: () => {
          this.$refs.vuetable.refresh()
        }
      })
    }
  },

  computed: {
    isTrashVisible () {
      return this.selectedItems.length > 0
    },
    activeattr (programactive) {
      if (programactive) {
        return 'outline-success'
      } else {
        return 'outline-danger'
      }
    },
    canDeleteActivity () {
      var permissions = JSON.parse(localStorage.getItem('userPermissions'))
      return permissions.some(p => p.permissionName === 'Deleting Activities')
    }
  }
}
</script>
<style>
.modal-body{
  word-wrap: break-word;
}
</style>
